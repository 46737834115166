<template>
  <div class="">
    <div class="container" v-if="validUrl">
      <div class="mt-5">
        <UploadPart view="page"
                    :blockId="blockId"
                    :userId="userId"
                    :uploadedFiles="files"
                    :lessonId="['lesson','homework'].includes(viewMode) ? id : null"
                    :materialId="viewMode == 'selfstudy' ? id : null"
                    :viewMode="viewMode"
                    @filesUploaded="filesUploaded"
                    :slideId="slideId"/>
      </div>
    </div>
  </div>
</template>

<script>

import UploadPart from "@/views/parts/class/IM02UploadPart"
import { ConstructorService, LessonsService } from "../../../services/api.service"
import io from 'socket.io-client'

export default {
  name: "BlockUpload",
  components: {
    UploadPart
  },
  metaInfo() {
    return {
      title: this.$t('upload'),
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      blockId: this.$route.params.bid,
      slideId: this.$route.params.sid,
      userId: this.$route.params.uid,
      routeHash: this.$route.params.hash,
      viewMode: this.$route.params.view,
      files: [],
      validUrl: false,
      socket: null,
    }
  },
  computed: {
    hash() {
      let str = `${this.slideId}-${this.blockId}-${this.userId}-${this.id}-${this.viewMode}`
      return this.hashCode(str)
    }
  },
  mounted() {
    this.validUrl = this.hash == this.routeHash
    this.socket = io(process.env.VUE_APP_SOCKET_SERVER, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000
    })
    this.socket.emit('join-room', this.id, this.userId, this.userId, false) // broadcasts "user-connected"
    ConstructorService.getImages(this.slideId, this.blockId, this.userId, this.id, this.viewMode).then(res => {
      this.files = res.data.data
    })
  },
  methods: {
    async filesUploaded(files) {
      await LessonsService.saveTaskStorageThroughQR({
        id: this.id,
        type: this.viewMode,
        block_id: this.blockId,
        user_id: this.userId,
        data: {
          wnr: files.length ? ['r'] : ['w'],
          files: files
        }
      })
      this.files = files
      if(this.socket && ['lesson','homework'].includes(this.viewMode)) {
        console.log('emitting file change event')
        this.socket.emit('customevent', 'qr-uploaded', this.id, { userId: this.userId, blockId: this.blockId })
      }
    },
    hashCode(str) {
      const crypto = require('crypto')
      return crypto.createHash('sha1').update(str).digest('hex')
    }
  }
}
</script>